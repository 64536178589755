import { FC, useRef } from 'react'
import AboutImage from '../../../assets/about-image.png'
import { ReactComponent as AboutIcon } from '../../../assets/about.svg'
import useScrolledTo from '../../../hooks/useScrolledTo'

const About: FC = () => {
	const titleRef = useRef<HTMLDivElement | null>(null)
	const textRef = useRef<HTMLDivElement | null>(null)
	const truckRef = useRef<HTMLImageElement | null>(null)
	const titleVisible = useScrolledTo(titleRef, 100)
	const textVisible = useScrolledTo(textRef, 100)
	const truckVisible = useScrolledTo(truckRef)

	return (
		<div className='section about' id='about'>
			<div className='container'>
				<div className={'section__title' + (titleVisible ? ' on-screen' : '')} ref={titleRef}>
					<h2 className='section__title-text'>About us</h2>
					<div className='section__title-icon'>
						<AboutIcon />
					</div>
				</div>
				<div className={'about__text' + (textVisible ? ' on-screen' : '')} ref={textRef}>
					<p>“<span>SoloKingWay Corporation</span>” is the best logistics trucking company in Ohio.</p>
					<p style={{ marginRight: 60 }}>If you want to work with real professionals and get the best service with the
					                               highest gross, we are the ones to call. </p>
					<p style={{ marginRight: 35 }}>We are the professionals who know all the subtleties, the peculiarities of
					                               business and deliver the goods
					                               to the destination on time, no matter what.</p>
					<p>Our specialists' accumulated extensive experience, professionalism, and responsibility at every stage from
					   planning the route to directly delivering load are the guaranteed quality and reliability for our
					   clients.</p>
				</div>
				<img
					className={'about__image' + (truckVisible ? ' on-screen' : '')}
					src={AboutImage}
					alt='Truck'
					ref={truckRef}
				/>
			</div>
		</div>
	)
}

export default About
