import { FC, useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as MenuIcon } from '../../assets/menu.svg'
import scrollTo from '../../utils/scrollTo'

const Navbar: FC = () => {
	const [ open, setOpen ] = useState<boolean>(false)
	const [ scrolled, setScrolled ] = useState<boolean>(false)

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 0) {
				setScrolled(true)
			} else {
				setScrolled(false)
			}
		})
	}, [])

	useEffect(() => {
		if (open) {
			document.body.classList.add('disabled')
		} else {
			document.body.classList.remove('disabled')
		}
	}, [ open ])

	return (
		<div className={'navbar' + (scrolled ? ' scrolled' : '')}>
			<div className='container'>
				<div className='navbar__logo'>
					<Logo />
				</div>
				<ul className={'navbar__nav' + (open ? ' open' : '')}>
					<li
						className='nav-item'
						onClick={() => {
							setOpen(false)
							scrollTo('main')
						}}
					>Home
					</li>
					<li
						className='nav-item'
						onClick={() => {
							setOpen(false)
							scrollTo('services')
						}}
					>Services
					</li>
					<li
						className='nav-item'
						onClick={() => {
							setOpen(false)
							scrollTo('about')
						}}
					>About
					</li>
					<li
						className='nav-item'
						onClick={() => {
							setOpen(false)
							scrollTo('application')
						}}
					>Apply now
					</li>
					<li
						className='nav-item'
						onClick={() => {
							setOpen(false)
							scrollTo('contacts')
						}}
					>Contact us
					</li>
					<div className='navbar__moreinfo'>
						<a href='tel:+16468688244'>
							<p className='navbar__moreinfo-phone'>+1 (646) 868-8244</p>
							<span className='navbar__moreinfo-call'>Call recruit</span>
						</a>
						<button
							className='navbar__moreinfo-btn' onClick={() => {
							setOpen(false)
							scrollTo('application')
						}}
						>
							Quick Application
						</button>
						<a
							target='_blank'
							rel='noreferrer'
							href='https://intelliapp.driverapponline.com/s/1955147?uri_b=so_1955147_1574203037'
							className='navbar__moreinfo-btn'
						>
							Full application
						</a>
						<div className='navbar__moreinfo-social'>
							<a
								target='_blank'
								rel='noreferrer'
								href='https://instagram.com/solokingway_corp?igshid=YmMyMTA2M2Y='
							>
								Instagram
							</a>
							<a
								target='_blank'
								rel='noreferrer'
								href='https://api.whatsapp.com/send?phone=16468760099&app=facebook&entry_point=page_cta'
							>
								WhatsApp
							</a>
							<a
								target='_blank'
								rel='noreferrer'
								href='https://www.facebook.com/Solokingway-Corporation-257226416156757/'
							>
								Facebook
							</a>
							<a
								target='_blank'
								rel='noreferrer'
								href='https://t.me/TedSKW'
							>
								Telegram
							</a>
						</div>
					</div>
					<button className='navbar__nav-close' onClick={() => setOpen(false)}>
						<CloseIcon />
					</button>
				</ul>
				<button className='navbar__toggle' onClick={() => setOpen(true)}>
					<MenuIcon />
				</button>
			</div>
		</div>
	)
}

export default Navbar
