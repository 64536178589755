import emailjs from '@emailjs/browser'
import { FC, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { ReactComponent as FileIcon } from '../../assets/file.svg'
import PrivacyPolicy from '../../assets/privacy-policy.pdf'
import { ReactComponent as SendIcon } from '../../assets/send.svg'
import Dropdown from '../Dropdown'

const states = [
	'Alabama',
	'Alaska',
	'American Samoa',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'District Of Columbia',
	'Federated States Of Micronesia',
	'Florida',
	'Georgia',
	'Guam',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Marshall Islands',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Northern Mariana Islands',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Palau',
	'Pennsylvania',
	'Puerto Rico',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virgin Islands',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming'
] as const

type state = typeof states[number]

const jobPositions = [ 'Owner operator', 'Lease option', 'Company driver' ] as const

type position = typeof jobPositions[number]

export interface IApplicant {
	firstName: string
	lastName: string
	email: string
	phone: string
	driverLicenseNumber: string
	driverLicenseFile: File
	state: state
	position: position
}

const Form: FC = () => {
	const [ sending, setSending ] = useState<boolean>(false)
	const reCAPTCHA = useRef<ReCAPTCHA | null>(null)

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		watch,
		setValue,
		control,
		reset
	} = useForm<IApplicant>()

	const onSubmit: SubmitHandler<IApplicant> = (data) => {

		setSending(true)

		emailjs.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
			process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
			{
				...data,
				driverLicenseFile: data.driverLicenseFile.name,
				'g-recaptcha-response': reCAPTCHA.current?.getValue()
			},
			process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
			.then(() => {
				emailjs.send(
					process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
					process.env.REACT_APP_EMAILJS_TEMPLATE_ID2 as string,
					{ ...data, driverLicenseFile: data.driverLicenseFile.name },
					process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
					.then(() => {
						setSending(false)
						setValue('phone', '')
						reCAPTCHA.current?.reset()
						reset()
					}, (err) => {
						setSending(false)
						reCAPTCHA.current?.reset()
						console.log(err)
					})
			}, (err) => {
				setSending(false)
				reCAPTCHA.current?.reset()
				console.log(err)
			})
	}

	const onFileUpload = () => {
		const inputFile = document.getElementById('driverLicenseFile') as HTMLInputElement
		setValue('driverLicenseFile', inputFile?.files?.item(0) as File)
		clearErrors('driverLicenseFile')
	}

	return (
		<form className='application__form' onSubmit={handleSubmit(onSubmit)} method='post'>
			<div className='application__form-content'>
				<div className='application__form-item'>
					<input
						type='text'
						className={`application__form-input
							${errors.firstName ? ' invalid' : ''}
							${watch('firstName') ? ' not-empty' : ''}`}
						{...register('firstName', { required: 'Please enter your name' })}
					/>
					<label htmlFor='firstName' className='application__form-label'>First name</label>
					{errors.firstName?.message && <span className='application__form-error'>{errors.firstName.message}</span>}
				</div>
				<div className='application__form-item'>
					<input
						type='text'
						className={`application__form-input
							${errors.lastName ? ' invalid' : ''}
							${watch('lastName') ? ' not-empty' : ''}`}
						{...register('lastName', { required: 'Please enter your last name' })}
					/>
					<label htmlFor='lastName' className='application__form-label'>Last name</label>
					{errors.lastName?.message && <span className='application__form-error'>{errors.lastName.message}</span>}
				</div>
				<div className='application__form-item'>
					<input
						type='email'
						className={`application__form-input
							${errors.email ? ' invalid' : ''}
							${watch('email') ? ' not-empty' : ''}`}
						{...register('email', {
							required: 'Please enter your email',
							pattern: {
								value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
								message: 'Please enter a valid email'
							}
						})}
					/>
					<label htmlFor='email' className='application__form-label'>Email</label>
					{errors.email?.message && <span className='application__form-error'>{errors.email.message}</span>}
				</div>
				<div className='application__form-item'>
					<InputMask
						type='tel'
						className={`application__form-input
							${errors.phone ? ' invalid' : ''}
							${watch('phone') ? ' not-empty' : ''}`}
						mask='+1 \(999) 999-9999'
						{...register('phone', {
							required: 'Please enter your phone number',
							pattern: {
								value: /^(\+1 \([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
								message: 'Please enter a valid phone number'
							}
						})}
					/>
					<label htmlFor='phone' className='application__form-label'>Phone number</label>
					{errors.phone?.message && <span className='application__form-error'>{errors.phone.message}</span>}
				</div>
				<div className='application__form-item'>
					<input
						type='text'
						className={`application__form-input
							${errors.driverLicenseNumber ? ' invalid' : ''}
							${watch('driverLicenseNumber') ? ' not-empty' : ''}`}
						{...register('driverLicenseNumber', { required: 'Please enter your driver\'s license number' })}
					/>
					<label htmlFor='driver-license-number' className='application__form-label'>Driver license number</label>
					{errors.driverLicenseNumber?.message &&
						<span className='application__form-error'>{errors.driverLicenseNumber.message}</span>}
				</div>
				<div className='application__form-item'>
					<input
						id='driverLicenseFile'
						type='file'
						className={`application__form-input
							${errors.driverLicenseFile ? ' invalid' : ''}
							${watch('driverLicenseFile') ? ' not-empty' : ''}`}
						onChange={onFileUpload}
					/>
					<input
						type='hidden'
						{...register('driverLicenseFile')}
					/>
					<label
						htmlFor='driver-license-file'
						className='application__form-label'
					>
						Add your driver license (optional)
					</label>
					{errors.driverLicenseFile?.message &&
						<span className='application__form-error'>{errors.driverLicenseFile.message}</span>}
					<span className='application__form-icon'>
						<FileIcon />
					</span>
				</div>
				<div className='application__form-item'>
					<Controller
						control={control}
						name='state'
						rules={{ required: 'Please select your state' }}
						render={({ field: { name, value }, fieldState: { error } }) => (
							<Dropdown
								options={states}
								name={name}
								label='State'
								value={value}
								error={error}
								setValue={setValue}
								clearError={() => clearErrors(name)}
							/>
						)}
					/>
				</div>
				<div className='application__form-item'>
					<Controller
						control={control}
						name='position'
						rules={{ required: 'Please select one of the positions listed below' }}
						render={({ field: { name, value }, fieldState: { error } }) => (
							<Dropdown
								options={jobPositions}
								name={name}
								label='Which position are you interested in?'
								value={value}
								error={error}
								setValue={setValue}
								clearError={() => clearErrors(name)}
							/>
						)}
					/>
				</div>
			</div>
			<small className='application__form-agreement'>
				By clicking "Apply" button, I expressly understand that my given below personal information will be used
				according to SoloKingWay Corporation<a href={PrivacyPolicy} target='_blank' rel='noreferrer'> Privacy Policy</a>
			</small>
			<ReCAPTCHA
				sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
				style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				ref={reCAPTCHA}
				theme='dark'
			/>
			<br />
			<button
				className={'application__form-button' + (sending ? ' loading' : '')}
			>
				<span className='icon'>
					<SendIcon />
				</span>
				<span className='text'>Apply</span>
				<span className='line' />
			</button>
		</form>
	)
}

export default Form
