import { FC, useRef } from 'react'
import AnnAnderson from '../../../assets/ann-anderson.jpg'
import { ReactComponent as BubblesIcon } from '../../../assets/bubbles.svg'
import JonathanBell from '../../../assets/jonathan-bell.jpg'
import JulieAdams from '../../../assets/julie-adams.jpg'
import RandyStewart from '../../../assets/randy-stewart.jpg'
import Review1 from '../../../assets/review-1.jpg'
import Review2 from '../../../assets/review-2.jpg'
import Review3 from '../../../assets/review-3.jpg'
import Review4 from '../../../assets/review-4.jpg'
import Review5 from '../../../assets/review-5.jpg'
import WayneMiller from '../../../assets/wayne-miller.jpg'
import useScrolledTo from '../../../hooks/useScrolledTo'
import Slider from '../../Slider'

interface IReview {
	id: number
	avatar: string
	name: string
	review: string
	background: string
}

const reviews: IReview[] = [
	{
		id: 1,
		avatar: JonathanBell,
		name: 'Jonathan Bell',
		review: 'There can be no questions. The company is cool, the service that they provide to their customers is at the highest level, I was only satisfied, thanks to all of you and good luck.',
		background: Review1
	},
	{
		id: 2,
		avatar: JulieAdams,
		name: 'Julie Adams',
		review: 'My husband is the driver in this company and they have already become a second family for him. He likes to work with them, he especially loves his dispatcher, and every time he communicates with him as with a younger brother.',
		background: Review2
	},
	{
		id: 3,
		avatar: WayneMiller,
		name: 'Wayne Miller',
		review: 'My friend has been working with them for more than a year and the feedback from him has always been excellent. He loves his job because he works with the right people. I advise them everyone who is looking for a good atmosphere at work.',
		background: Review3
	},
	{
		id: 4,
		avatar: RandyStewart,
		name: 'Randy Stewart',
		review: 'I think the quality of work they provide is on par with the best companies in the industry. I wish them all the very best and hope to see you soon.',
		background: Review4
	},
	{
		id: 5,
		avatar: AnnAnderson,
		name: 'Ann Anderson',
		review: 'What can I say? It’s worth working with them. They are real professionals who exactly knows every aspect and do all work with great ways.',
		background: Review5
	}
]

const Reviews: FC = () => {
	const titleRef = useRef<HTMLDivElement | null>(null)
	const titleVisible = useScrolledTo(titleRef, 100)

	return (
		<div className='section reviews' id='reviews'>
			<div className={'section__title' + (titleVisible ? ' on-screen' : '')} ref={titleRef}>
				<h2 className='section__title-text'>What people say</h2>
				<div className='section__title-icon'>
					<BubblesIcon />
				</div>
			</div>
			<Slider>
				{reviews.map(review => (
					<div key={review.id} className='review'>
						<img src={review.avatar} alt={review.name} className='review__avatar' />
						<div className='review__content'>
							<h2 className='review__name'>{review.name}</h2>
							<p className='review__text'>{review.review}</p>
						</div>
						<img className='review__bg' src={review.background} alt='Review' />
					</div>
				))}
			</Slider>
		</div>
	)
}

export default Reviews
