import { FC, useRef } from 'react'
import { ReactComponent as ApplicationIcon } from '../../../assets/application.svg'
import useScrolledTo from '../../../hooks/useScrolledTo'
import Form from '../../Form'

const Application: FC = () => {
	const titleRef = useRef<HTMLDivElement | null>(null)
	const title2Ref = useRef<HTMLDivElement | null>(null)
	const title3Ref = useRef<HTMLDivElement | null>(null)
	const subtitleRef = useRef<HTMLDivElement | null>(null)
	const subtitle2Ref = useRef<HTMLDivElement | null>(null)
	const titleVisible = useScrolledTo(titleRef, 100)
	const title2Visible = useScrolledTo(title2Ref)
	const title3Visible = useScrolledTo(title3Ref)
	const subtitleVisible = useScrolledTo(subtitleRef)
	const subtitle2Visible = useScrolledTo(subtitle2Ref)

	return (
		<div className='section application' id='application'>
			<div className='container'>
				<div className={'section__title' + (titleVisible ? ' on-screen' : '')} ref={titleRef}>
					<h2 className='section__title-text'>Driver's application</h2>
					<div className='section__title-icon'>
						<ApplicationIcon />
					</div>
				</div>
				<h2 className={'application__title' + (title2Visible ? ' on-screen' : '')} ref={title2Ref}>In a hurry?</h2>
				<p className={'application__subtitle' + (subtitleVisible ? ' on-screen' : '')} ref={subtitleRef}>
					No problem. Give us the basic information and we will get back to you ASAP
				</p>
				<Form />
				<h2 className={'application__title' + (title3Visible ? ' on-screen' : '')} ref={title3Ref}>Have More Time?</h2>
				<p className={'application__subtitle' + (subtitle2Visible ? ' on-screen' : '')} ref={subtitle2Ref}>
					Then you can complete entire application{' '}
					<a
						target='_blank'
						rel='noreferrer'
						href='https://intelliapp.driverapponline.com/s/1955147?uri_b=so_1955147_1574203037'
					>here</a>
				</p>
			</div>
		</div>
	)
}

export default Application
