import { RefObject, useEffect } from 'react'

export default function useClickOutside(ref: RefObject<Element | null>, callback: () => void): void {
	const handleClick = (e: MouseEvent) => {
		if (ref.current && !ref.current.contains(e.target as Node)) {
			callback()
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClick)
		return () => {
			document.removeEventListener('click', handleClick)
		}
	})
}
