import { FC } from 'react'
import { ReactComponent as EmailIcon } from '../../assets/email.svg'
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg'
import LogoFooter from '../../assets/footer.png'
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg'
import { ReactComponent as LocationIcon } from '../../assets/location.svg'
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg'
import PrivacyPolicy from '../../assets/privacy-policy.pdf'
import { ReactComponent as TelegramIcon } from '../../assets/telegram.svg'
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp.svg'
import scrollTo from '../../utils/scrollTo'

const Footer: FC = () => {
	return (
		<div className='section footer' id='contacts'>
			<div className='container'>
				<div className='footer__logo'>
					<img src={LogoFooter} alt='Logo' />
				</div>
				<div className='footer__getintouch'>
					<h3 className='getintouch__title footer__title'>Get in touch</h3>
					<div className='getintouch__location getintouch__row'>
						<div className='getintouch__location-icon getintouch__icon'>
							<LocationIcon />
						</div>
						<a
							className='getintouch__location-content'
							href='https://goo.gl/maps/bJFcwToxkN3amYPdA'
							target='_blank'
							rel='noreferrer'
						>
							<p style={{ textTransform: 'uppercase' }}>SoloKingWay Corp</p>
							<p>32 Bay 7th street</p>
							<p>Brooklyn, NY 11228</p>
						</a>
					</div>
					<div className='getintouch__phone getintouch__row'>
						<div className='getintouch__phone-icon getintouch__icon'>
							<PhoneIcon />
						</div>
						<a href='tel:+16468688244' className='getintouch__phone-content'>+1 (646) 868-8244</a>
					</div>
					<div className='getintouch__email getintouch__row'>
						<div className='getintouch__email-icon getintouch__icon'>
							<EmailIcon />
						</div>
						<a
							href='mailto:solokingwaycorp@gmail.com'
							className='getintouch__email-content'
						>solokingwaycorp@gmail.com</a>
					</div>
					<div className='getintouch__socialmedia'>
						<a
							className='getintouch__socialmedia-icon'
							data-label='instagram'
							target='_blank'
							rel='noreferrer'
							href='https://instagram.com/solokingway_corp?igshid=YmMyMTA2M2Y='
						>
							<InstagramIcon />
						</a>
						<a
							className='getintouch__socialmedia-icon'
							data-label='facebook'
							target='_blank'
							rel='noreferrer'
							href='https://www.facebook.com/Solokingway-Corporation-257226416156757/'
						>
							<FacebookIcon />
						</a>
						<a
							className='getintouch__socialmedia-icon'
							data-label='whatsapp'
							target='_blank'
							rel='noreferrer'
							href='https://api.whatsapp.com/send?phone=16468760099&app=facebook&entry_point=page_cta'
						>
							<WhatsappIcon />
						</a>
						<a
							className='getintouch__socialmedia-icon'
							data-label='telegram'
							target='_blank'
							rel='noreferrer'
							href='https://t.me/TedSKW'
						>
							<TelegramIcon />
						</a>
					</div>
				</div>
				<div className='footer__quicklinks'>
					<h3 className='quicklinks__title footer__title'>Quick links</h3>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://intelliapp.driverapponline.com/s/1955147?uri_b=so_1955147_1574203037'
						className='quicklinks__btn'
					>Full driver application</a>
					<button className='quicklinks__btn' onClick={() => scrollTo('services')}>Our services</button>
					<button className='quicklinks__btn' onClick={() => scrollTo('main')}>Go to main</button>
				</div>
				<div className='footer__hours'>
					<h3 className='hours__title footer__title'>Business hours</h3>
					<ul className='hours__days'>
						<li className={'hours__day' + (new Date().getDay() === 1 ? ' active' : '')}>
							<span>Monday:</span>
							<span>8 am - 6 pm</span>
						</li>
						<li className={'hours__day' + (new Date().getDay() === 2 ? ' active' : '')}>
							<span>Tuesday:</span>
							<span>8 am - 6 pm</span>
						</li>
						<li className={'hours__day' + (new Date().getDay() === 3 ? ' active' : '')}>
							<span>Wednesday:</span>
							<span>8 am - 6 pm</span>
						</li>
						<li className={'hours__day' + (new Date().getDay() === 4 ? ' active' : '')}>
							<span>Thursday:</span>
							<span>8 am - 6 pm</span>
						</li>
						<li className={'hours__day' + (new Date().getDay() === 5 ? ' active' : '')}>
							<span>Friday:</span>
							<span>8 am - 6 pm</span>
						</li>
						<li className={'hours__day' + (new Date().getDay() === 6 ? ' active' : '')}>
							<span><b>Saturday:</b></span>
							<span><b>9 am - 3 pm</b></span>
						</li>
						<li className={'hours__day' + (new Date().getDay() === 0 ? ' active' : '')}>
							<span><b>Sunday:</b></span>
							<span><b>9 am - 3 pm</b></span>
						</li>
					</ul>
					<p className='hours__subtitle'>Support team is available 24/7</p>
				</div>
				<p className='copyright'>
					© 2020-{new Date().getFullYear()} solokingway corp. All rights reserved. <a
					href={PrivacyPolicy}
					target='_blank'
					rel='noreferrer'
				>Privacy Policy</a>
				</p>
				<p className='authors'>Creators: web-developer <a
					target='_blank'
					rel='noreferrer'
					href='https://www.instagram.com/__shukha__/'
				>
					Shukhrat R.
				</a> & designer <a
					target='_blank'
					rel='noreferrer'
					href='https://www.instagram.com/dfiruzov/'
				>
					Daler F.
				</a>
				</p>
			</div>
		</div>
	)
}

export default Footer
