import { Suspense } from 'react'
import Footer from './components/Footer'
import Loader from './components/Loader'
import Navbar from './components/Navbar'
import About from './components/sections/About'
import Application from './components/sections/Application'
import Contacts from './components/sections/Contacts'
import Main from './components/sections/Main'
import Reviews from './components/sections/Reviews'
import Services from './components/sections/Services'

function App() {
	return (
		<Suspense fallback={<Loader />}>
			<Navbar />
			<Main />
			<Services />
			<About />
			<Reviews />
			<Contacts />
			<Application />
			<Footer />
		</Suspense>
	)
}

export default App
