export default function scrollTo(id: string) {
	const target: HTMLElement | null = document.getElementById(id)
	if (target === null) return
	const targetPosition: number = target.clientHeight < window.innerHeight
	                               ? target.offsetTop - ((window.innerHeight - target.clientHeight) / 2)
	                               : target.offsetTop
	const startPosition: number = window.scrollY
	const distance: number = targetPosition - startPosition
	let startTime: number | null = null
	let duration: number = Math.round(Math.abs(distance) / 1000) * 200
	duration = duration < 500 ? 500 : duration

	function animation(currentTime: number) {
		if (startTime === null) startTime = currentTime
		const timeElapsed: number = currentTime - startTime
		const run = easeInOut(timeElapsed, startPosition, distance, duration)
		window.scrollTo(0, run)
		if (timeElapsed < duration) requestAnimationFrame(animation)
	}

	function easeInOut(t: number, b: number, c: number, d: number) {
		t /= d
		return -c * t * (t - 2) + b
	}

	requestAnimationFrame(animation)
}
