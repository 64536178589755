import { FC, useRef } from 'react'
import { ReactComponent as ServicesIcon } from '../../../assets/services.svg'
import useScrolledTo from '../../../hooks/useScrolledTo'
import Cards from '../../Cards'

const Services: FC = () => {
	const titleRef = useRef<HTMLDivElement | null>(null)
	const visible = useScrolledTo(titleRef, 100)

	return (
		<div className='section services' id='services'>
			<div className='container'>
				<div className={'section__title' + (visible ? ' on-screen' : '')} ref={titleRef}>
					<h2 className='section__title-text'>Our services</h2>
					<div className='section__title-icon'>
						<ServicesIcon />
					</div>
				</div>
				<Cards />
			</div>
		</div>
	)
}

export default Services
