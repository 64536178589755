import { Children, FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg'
import useScrolledTo from '../../hooks/useScrolledTo'

interface SliderProps {
	children: JSX.Element[]
}

const Slider: FC<SliderProps> = ({ children }) => {
	const containerRef = useRef<HTMLUListElement | null>(null)
	const [ currentSlide, setCurrentSlide ] = useState<number>(1)
	const [ translateX, setTranslateX ] = useState<number>(0)
	const [ disabled, setDisabled ] = useState<boolean>(false)
	const sliderRef = useRef<HTMLDivElement | null>(null)
	const sliderVisible = useScrolledTo(sliderRef, 100)

	const actionHandler = (mode: 'prev' | 'next') => {
		if (containerRef.current) {
			containerRef.current.style.transitionDuration = '.5s'
		}

		if (mode === 'prev') {
			if (currentSlide <= 1) {
				setTranslateX(0)
				setCurrentSlide(children.length)
			} else {
				containerRef.current && setTranslateX(containerRef.current.clientWidth * (currentSlide - 1))
				setCurrentSlide(prev => --prev)
			}
		} else if (mode === 'next') {
			if (currentSlide >= children.length) {
				containerRef.current && setTranslateX(containerRef.current.clientWidth * (children.length + 1))
				setCurrentSlide(1)
			} else {
				containerRef.current && setTranslateX(containerRef.current.clientWidth * (currentSlide + 1))
				setCurrentSlide(prev => ++prev)
			}
		}
	}

	useEffect(() => {
		const transitionEnd = () => {
			if (currentSlide <= 1) {
				if (containerRef.current) {
					containerRef.current.style.transitionDuration = '0s'
					setTranslateX(containerRef.current.clientWidth * currentSlide)
				}
			}

			if (currentSlide >= children.length) {
				if (containerRef.current) {
					containerRef.current.style.transitionDuration = '0s'
					setTranslateX(containerRef.current.clientWidth * children.length)
				}
			}
			setDisabled(false)
		}

		if (containerRef.current) {
			containerRef.current.addEventListener('transitionstart', () => {
				setDisabled(true)
			})
			containerRef.current.addEventListener('transitionend', transitionEnd)
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener('transitionend', transitionEnd)
			}
		}
	}, [ currentSlide, children ])

	const slides = useMemo(() => {
		if (children.length > 1) {
			let items = Children.map(children, (child, i) => (
				<li key={i} className='reviews__slider-item'>
					{child}
				</li>
			))

			return [
				<li key={children.length + 1} className='reviews__slider-item'>
					{children[children.length - 1]}
				</li>,
				...items,
				<li key={children.length + 2} className='reviews__slider-item'>
					{children[0]}
				</li>
			]
		}

		return <li className='reviews__slider-item'>{children[0]}</li>
	}, [ children ])

	useLayoutEffect(() => {
		containerRef.current && setTranslateX(containerRef.current.clientWidth * currentSlide)
	}, [])

	return (
		<div className={'reviews__slider' + (sliderVisible ? ' on-screen' : '')} ref={sliderRef}>
			<ul
				ref={containerRef}
				className='reviews__slider-container'
				style={{
					transform: `translate3d(${-translateX}px, 0, 0)`
				}}
			>
				{slides}
			</ul>
			<div className='reviews__slider-buttons container'>
				<button
					className='reviews__slider-button left'
					onClick={() => actionHandler('prev')}
					disabled={disabled}
				><ArrowLeft /></button>
				<button
					className='reviews__slider-button right'
					onClick={() => actionHandler('next')}
					disabled={disabled}
				><ArrowRight /></button>
			</div>
			<ul className='reviews__slider-dots'>
				{
					Array.from({ length: children.length }, (_, i) => i + 1).map(dot => (
						<li key={dot} className={'dot' + (currentSlide === dot ? ' active' : '')} />
					))
				}
			</ul>
		</div>
	)
}

export default Slider
