import { FC, useEffect, useRef } from 'react'
import { ReactComponent as ArrowDown } from '../../../assets/arrow-down.svg'
import MP4 from '../../../assets/main-background.mp4'
import WEBM from '../../../assets/main-background.webm'
import scrollTo from '../../../utils/scrollTo'

const Main: FC = () => {
	const mainRef = useRef<HTMLDivElement | null>(null)
	const videoRef = useRef<HTMLVideoElement | null>(null)

	useEffect(() => {
		if (mainRef.current && videoRef.current) {
			mainRef.current.style.height = window.innerHeight + 'px'
			videoRef.current.style.height = window.innerHeight + 'px'

		}
	}, [])

	return (
		<div className='main' id='main' ref={mainRef}>
			<video
				className='main__background-video'
				muted
				loop
				autoPlay
				playsInline
				controls={false}
				ref={videoRef}
			>
				<source src={WEBM} type='video/webm' />
				<source src={MP4} type='video/mp4' />
			</video>
			<div className='container'>
				<div className='main__offer'>
					<h1 className='main__offer-title'>SoloKingWay</h1>
					<p className='main__offer-text'>The pearl of the truck industry</p>
					<button className='main__offer-btn' onClick={() => scrollTo('application')}>Join us</button>
				</div>
				<button className='main__scroll-down' onClick={() => scrollTo('services')}>
					<p>Scroll down</p>
					<div className='main__scroll-down-icon'>
						<ArrowDown />
					</div>
				</button>
			</div>
		</div>
	)
}

export default Main
