import { FC, useEffect, useRef } from 'react'
import ContactsImage from '../../../assets/contacts.png'
import useScrolledTo from '../../../hooks/useScrolledTo'

const Contacts: FC = () => {
	const titleRef = useRef<HTMLDivElement | null>(null)
	const textRef = useRef<HTMLParagraphElement | null>(null)
	const buttonRef = useRef<HTMLAnchorElement | null>(null)
	const imageRef = useRef<HTMLImageElement | null>(null)
	const titleVisible = useScrolledTo(titleRef, 100)
	const textVisible = useScrolledTo(textRef)
	const buttonVisible = useScrolledTo(buttonRef, 100)
	const imageVisible = useScrolledTo(imageRef, 100)

	useEffect(() => {
		const dataText = 'Talk to a recruiter about driver jobs in your area.'

		function typeWriter(text: string, i: number, fnCallback: () => void) {
			if (textVisible && i < (text.length)) {
				if (textRef.current == null) return
				textRef.current.innerHTML = text.substring(0, i + 1)

				setTimeout(function () {
					typeWriter(text, i + 1, fnCallback)
				}, 50)
			}
		}

		function StartTextAnimation(i: number) {
			if (i < dataText.length) {
				typeWriter(dataText, 0, function () {
					StartTextAnimation(i + 1)
				})
			}
		}

		StartTextAnimation(0)
	}, [ textVisible ])

	return (
		<div className='section contacts'>
			<div className='container'>
				<div className='contacts__content'>
					<h2
						className={'contacts__content-title' + (titleVisible ? ' on-screen' : '')}
						ref={titleRef}
					>
						Interested in driving <small>for </small>
						<span>SoloKingWay</span><small>?</small>
					</h2>
					<p
						className='contacts__content-text'
						ref={textRef}
					>{' '}</p>
					<a
						className={'contacts__content-btn' + (buttonVisible ? ' on-screen' : '')}
						href='tel:+16468688244'
						ref={buttonRef}
					>
						Call us: +1 (646) 868-8244
					</a>
				</div>
				<img
					src={ContactsImage}
					alt='Truck'
					className={'contacts__image' + (imageVisible ? ' on-screen' : '')}
					ref={imageRef}
				/>
			</div>
		</div>
	)
}

export default Contacts
