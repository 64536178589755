import { FC } from 'react'

const Loader: FC = () => {
	return (
		<div className='loader'>
			<span className='loader__line' />
			<span className='loader__line' />
			<span className='loader__line' />
			<span className='loader__line' />
			<span className='loader__line' />
		</div>
	)
}

export default Loader
