import { FC, KeyboardEventHandler, useEffect, useRef, useState } from 'react'
import { FieldError, UseFormSetValue } from 'react-hook-form'
import { ReactComponent as DropdownArrowIcon } from '../../assets/dropdown-arrow.svg'
import useClickOutside from '../../hooks/useClickOutside'

interface IDropdown {
	options: readonly string[]
	name: string
	label: string
	value: string
	error: FieldError | undefined
	setValue: UseFormSetValue<any>
	clearError: () => void
}

const Dropdown: FC<IDropdown> = ({ options, name, label, value, error, setValue, clearError }) => {
	const [ open, setOpen ] = useState<boolean>(false)
	const [ index, setIndex ] = useState<number>(-1)
	const buttonRef = useRef<HTMLInputElement | null>(null)
	const listRef = useRef<HTMLUListElement | null>(null)

	useClickOutside(buttonRef, () => {
		if (open) {
			setOpen(false)
		}

		return
	})

	const select = (i: number) => {
		setIndex(i)
		setValue(name, options[i])
		clearError()
		setOpen(false)
	}

	const handleKeyDown: KeyboardEventHandler = (e) => {
		switch (e.key) {
			case ' ':
			case 'SpaceBar':
			case 'Enter':
				e.preventDefault()
				select(index)
				break
			default:
				break
		}
	}

	const handleChangeOption: KeyboardEventHandler = (e) => {
		switch (e.key) {
			case 'Escape':
				e.preventDefault()
				setOpen(false)
				break
			case 'ArrowUp':
				e.preventDefault()
				setIndex(prev => prev - 1 >= 0 ? prev - 1 : options.length - 1)
				break
			case 'ArrowDown':
				e.preventDefault()
				setIndex(prev => prev + 1 >= options.length ? 0 : prev + 1)
				break
			default:
				break
		}
	}

	useEffect(() => {
		if (open) {
			listRef.current?.scroll({ behavior: 'smooth', top: (index - 2) * 46 })
		}
	}, [ index, open ])

	return (
		<>
			<input
				type='button'
				className={`application__form-input dropdown
							${error ? ' invalid' : ''}
							${value ? ' not-empty' : ''}`}
				name={name}
				aria-expanded={open}
				onClick={() => setOpen(prev => !prev)}
				value={value || ''}
				ref={buttonRef}
			/>
			<label htmlFor={name} className='application__form-label'>{label}</label>
			{error?.message && <span className='application__form-error'>{error.message}</span>}
			<span className='application__form-icon'>
				<DropdownArrowIcon />
			</span>
			<ul
				className='dropdown__list'
				role='listbox'
				ref={listRef}
				aria-activedescendant={options[index]}
				tabIndex={-1}
				onKeyDown={handleChangeOption}
			>
				{options.map((option, i) => (
					<li
						className='dropdown__list-item'
						key={i}
						role='option'
						aria-selected={index === i}
						tabIndex={open ? 0 : -1}
						onClick={() => {
							select(i)
						}}
						onKeyDown={handleKeyDown}
					>{option}</li>
				))}
			</ul>
		</>
	)
}

export default Dropdown
