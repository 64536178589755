import { FC, useRef } from 'react'
import LogbookImage from '../../assets/logbook.jpg'
import LogisticsImage from '../../assets/logistics.jpg'
import TransportationImage from '../../assets/transportation.jpg'
import useScrolledTo from '../../hooks/useScrolledTo'

interface ICard {
	id: number
	title: string
	text: string[]
	image: string
}

const CardsArray: ICard[] = [
	{
		id: 1,
		title: 'Logistics',
		text: [
			'All the elements of your supply chain, from the factory to the end customer. Our service includes transportation from the manufacturer to the warehouse, warehousing and order fulfillment, and delivery to the end customer.',
			'Supply chain management from manufacturer to fulfillment center.\nOur dispatchers evaluate delivery options and find suitable cargo by evaluating their value.'
		],
		image: LogisticsImage
	},
	{
		id: 2,
		title: 'Transportation',
		text: [
			'With 2 years\' experience in the trucking industry, we specialize in finding the most suitable loads, that satisfy both your requirements and ours.',
			'We arrange for you excellent service within 24 hours every day and provide loads that are ideally suited to your schedule',
			'In terms of logistics services, as a reputable trucking company, we rely on extensive equipment as a way of carefully selecting each customer.'
		],
		image: TransportationImage
	},
	{
		id: 3,
		title: 'Logbook',
		text: [
			'Commercial truck operators are required by law to take off at least 10 consecutive hours between shifts. Truckers cannot be on duty for more than 14 consecutive hours, and they’re not permitted to spend over 11 of those hours actually driving.',
			'Federal regulations also stipulate that truckers are limited to driving a total of 60 hours during a seven-day period, or 70 hours in an eight-day period. Truck drivers must then rest for a minimum of 34 consecutive hours prior to starting a new driving period. In our company laws must always be followed'
		],
		image: LogbookImage
	}
]

const Cards: FC = () => {
	const cardsRef = useRef<HTMLDivElement | null>(null)
	const visible = useScrolledTo(cardsRef, 100)

	return (
		<div className='services__cards' ref={cardsRef}>
			{CardsArray.map(card => (
					<div
						className={'services__card' + (visible ? ' on-screen' : '')}
						key={card.id}
						style={{ transitionDelay: (card.id - 1) * .3 + 's' }}
					>
						<img className='services__card-image' src={card.image} alt={card.title} />
						<div className='services__card-content'>
							<h2 className='services__card-title'>{card.title}</h2>
							<hr className='services__card-divider' />
							<div className='services__card-text'>
								{card.text.map((text, i) => (
										<p key={i}>{text}</p>
									)
								)}
							</div>
						</div>
					</div>
				)
			)}
		</div>
	)
}

export default Cards
