import { RefObject, useEffect, useState } from 'react'

export default function useScrolledTo(ref: RefObject<Element | null>, scrollOffset: number = 0) {
	const [ scrolled, setScrolled ] = useState<boolean>(false)

	useEffect(() => {
		const handleScrollAnimation = () => {
			if (ref.current == null) return
			const elementTop: number = ref.current.getBoundingClientRect().top

			const isScrolledTo = elementTop <= (document.documentElement.clientHeight - scrollOffset)

			if (isScrolledTo) setScrolled(true)
		}

		window.addEventListener('scroll', handleScrollAnimation)

		return () => {
			window.removeEventListener('scroll', handleScrollAnimation)
		}
	}, [ ref, scrollOffset ])

	return scrolled
}
